.home {
  display: flex;
  background-color: #212c40;
  height: 100vh;
  width: 100vw;
  overflow: hidden;

  .homeContainer {
    flex: 1;
    display: flex;
    flex-direction: column;
    width: 100%;

    .agencyName {
      padding: 20px 20px 0 20px;
      width: 100%;
      font-size: 24px;
      color: white;
      display: flex;
      align-items: center;
    }

    .content {
      display: flex;
      flex: 1;
      padding: 20px;
      gap: 20px;
      overflow: hidden;

      .column {
        flex: 1;
        display: flex;
        flex-direction: column;
        background-color: blue($color: #000000);
        padding: 20px;
        box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);

        &.callInfo {
          flex: 1;
          max-height: calc(100vh - 150px); // Adjust this to allow space for the footer
          overflow-y: auto; 

          h3 {
            margin-bottom: 10px;
            color: white;
          }

          h2 {
            margin-bottom: 20px;
            color: white;
          }

          p {
            color: white; 
          }

          .callTypeBox {
            display: flex;
            align-items: center;
            margin-bottom: 20px;
            padding: 10px;
            background-color: #333;
            color: white;
            border-radius: 5px; 
          }

          .callInfoBox {
            display: flex;
            align-items: center;
            margin-bottom: 20px;
            padding: 10px;
            background-color: #333;
            color: white;
            border-radius: 5px;
            
          }

          .callTypeIcon {
            margin-right: 10px; 
          }
        }

       &.map {
          h2 {
            margin-bottom: 20px;
            color: white;
          }

          p {
            color: white; 
          }

          .dispatch-tools {
            display: flex;
            flex-direction: column;
            margin-bottom: 20px;

            .button-group {
              display: flex;
              gap: 10px;
            }

            .dispatch-button {
              display: flex;
              align-items: center;
              background-color: #333;
              color: white;
              border: none;
              border-radius: 5px;
              padding: 10px;
              cursor: pointer;
              font-size: 14px;

              .dispatch-icon {
                margin-right: 10px;
              }

              &:hover {
                background-color: #444;
              }
            }
          }
        }

        &.callQueue {
          flex: 1;
          display: flex;
          flex-direction: column;
          max-width: 30%;
          color: white;


          h2 {
            margin-bottom: 20px;
            color: white;
          }

          p {
            color: white; 
          }
        }

        &.callQueue {
          flex: 1;
          display: flex;
          flex-direction: column;
          max-width: 30%;
          color: white;

          .callQueueTop,
          .callQueueBottom {
            flex: 1;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
            padding: 20px;
            background-color: blue($color: #000000);
            box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
            overflow-y: auto;
            max-height: calc(100vh - 210px); // Adjust this to allow space for the footer

            h2 {
              margin: 0 0 10px 0;
            }

            .table {
              width: 100%;
              .MuiTableCell-root {
                text-align: left;
                background-color: #282c34;
                color: white;
              }

              .MuiTableHead-root {
                .MuiTableCell-root {
                  background-color: #2f3338;
                  color: white;
                }
              }

              .MuiTableBody-root {
                .MuiTableRow-root {
                  &:nth-of-type(odd) {
                    background-color: #3a3f44;
                  }
                  &:nth-of-type(even) {
                    background-color: #2f3338;
                  }
                }
              }
            }
          }
        }
      }
    }

    .footer {
      padding: 10px;
      text-align: center;
      background-color: #333;
      color: white;
    }
  }
}

.callInfoBox {
  white-space: pre-line;
}

.lastUpdatedText {
  font-size: 16px;
  font-style: italic;
  margin-left: 3px;
  margin-top: 3px;
  color: green;
}

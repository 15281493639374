.builder-padding {
  margin-top: 30px;
}

@media (min-width: 768px) {
  .react-form-builder-toolbar {
    max-width: calc(22% - 25px) !important;
    position: fixed !important;
    right: 10px !important;
    top: 50px;
  }
}

.button-container {
  display: flex;
  justify-content: flex-start;
}

.form-header {
  display: flex;
  align-items: center;
  margin: 20px;

  .name-input {
    width: 30%;
    margin-right: 60px;
  }

  .description-input {
    width: 40%;
  }

  label {
    display: flex;
    align-items: center;
    gap: 10px;
    margin: 0;
  }

  input {
    width: 100%;
    font-size: 13px;
    padding: 5px;
    border: none;
    border-bottom: 1px solid gray;
  }
}

input[type="radio"][value="sealed" i] + .custom-control-label-passfail {
  padding: 7px;
  width: 90%;
  background-color: #e7e7e7;
  border-radius: 5px;
  margin-top: 16px;
}

input[type="radio"][value="broken" i] + .custom-control-label-passfail {
  padding: 7px;
  width: 90%;
  background-color: #e7e7e7;
  border-radius: 5px;
}

input[type="radio"][value="pass" i] + .custom-control-label-passfail {
  padding: 7px;
  width: 90%;
  background-color: #e7e7e7;
  border-radius: 5px;
  margin-top: 16px;
}

input[type="radio"][value="fail" i] + .custom-control-label-passfail {
  padding: 7px;
  width: 90%;
  background-color: #e7e7e7;
  border-radius: 5px;
}

.custom-radio-passfail {
  width: 47%;
  margin-right: 0 !important;
  padding: 0 !important;
  text-align: center;
}
.stationBoard {
  width: 100%;
  display: flex;
}

.stationBoardContainer {
  flex: 6;
}

.top, .bottom {
  -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
  box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
  padding: 10px;
  margin: 20px;
  display: flex;

  h1 {
    color: lightgray;
    font-size: 20px;
  }

  .left {
    flex: 1;
    text-align: center;

    label {
      display: flex;
      align-items: center;
      gap: 10px;
      justify-content: center;

      .icon {
        cursor: pointer;
      }
    }

    img {
      width: 125px;
      height: 125px;
    }

    button {
      display: block;
      width: 150px;
      padding: 10px;
      border: none;
      background-color: teal;
      color: white;
      font-weight: bold;
      cursor: pointer;
      margin: 10px auto;

      &:disabled{
        background-color: rgba(3, 92, 92, 0.322);
        cursor: not-allowed;
      }
    }
  }

  .right {
    flex: 2;
    display: flex;
    flex-direction: column; /* Ensure buttons are stacked vertically */

    form {
      display: flex;
      flex-wrap: wrap;
      gap: 30px;
      justify-content: space-around;

      .formInput {
        width: 40%;

        label {
          display: flex;
          align-items: center;
          gap: 10px;

          .icon {
            cursor: pointer;
          }

          .warning {
            color: red;
            font-size: 12px;
          }
        }

        label.toggle {
          border-bottom: 1px solid gray;
          padding: 5px;
          width: 100%;
          justify-content: space-between;
        }

        input {
          width: 100%;
          padding: 5px;
          border: none;
          border-bottom: 1px solid gray;
        }
        
        textarea {
          width: 100%;
          padding: 5px;
        }
      }
    }
    button {
      width: 100%; 
      padding: 10px;
      border: none;
      background-color: teal;
      color: white;
      font-weight: bold;
      cursor: pointer;
      margin: 10px 0; 

      &:disabled {
        background-color: rgba(3, 92, 92, 0.322);
        cursor: not-allowed;
      }
    }
  }
}

.title {
  font-size: 16px;
  color: lightgray;
  margin-bottom: 20px;
}

.groups {
  width: 100%;
  display: flex;

  .groupsContainer {
    flex: 6;

    .container {
      height: 600px;
      padding: 20px;

      .title {
        width: 100%;
        font-size: 24px;
        color: gray;
        margin-bottom: 10px;
        display: flex;
        align-items: center;

        .description {
          width: 100%;
          font-size: 24px;
          color: gray;
          margin-bottom: 10px;
          display: flex;
          align-items: center;

        .createButton {
          color: green;
          font-size: 16px;
          font-weight: 400;
          border: 1px solid green;
          padding: 5px;
          border-radius: 5px;
          background-color: white;
          cursor: pointer;
        }
       }
      }

      .groupsList {
        display: flex;
        flex-wrap: wrap;
        gap: 20px;
        padding: 20px;
  
        .group {
          width: 256px;
          border-radius: 10px;
          border: 1px solid gray;
          display: flex;
          flex-direction: column;
  
          .groupTitle {
            font-size: 20px;
            padding: 8px 16px;
            box-sizing: border-box;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-bottom: 1px solid gray;

            span {
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              margin-right: 5px;
            }

            .addIcon {
              cursor: pointer;
            }
          }

          .groupUsers {
            height: 140px;
            overflow-y: auto;

            &::-webkit-scrollbar {
              width: 5px;
            }
            &::-webkit-scrollbar-thumb {
              background-color: #888;
              border-radius: 20px;
            }
            
          }
  
          .groupUser {
            font-size: 16px;
            padding: 8px 16px;
            box-sizing: border-box;
            border-bottom: 1px solid gray;
            display: flex;
            justify-content: space-between;
            margin-bottom: 4px;

            .userInfo {
              display: flex;
              align-items: center;
              justify-content: start;
              img {
                width: 28px;
                height: 28px;
                border-radius: 50%;
                margin-right: 10px;
              }
            }

            .removeIcon {
              cursor: pointer;
              opacity: 0.3;
              transition: opacity 0.3s ease;

              &:hover {
                opacity: 1;
              }
            }
          }
  
        }
      }
    }
  }
}

.groupInput {
  input {
    width: 100%;
    border: none;
    border-bottom: 1px solid gray;
    padding: 5px;
    font-size: 13px;
  }
}
#rigcheck-name {
    text-align: center;
    font-size: 26px;
    font-weight: 900;
    color: #eb2529;
}

@media print {
    body * {
        visibility: hidden;
    }

    .datagrid * {
        visibility: hidden!important;
    }

    #qr-code * {
        visibility: visible;
    }

}
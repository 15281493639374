.single {
  display: flex;
  width: 100%;
  .singleContainer {
    flex: 6;

    .top,
    .bottom {
      -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
      box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
      padding: 10px;
      margin: 20px;
      display: flex;

      h1 {
        color: lightgray;
        font-size: 20px;
      }

      .left {
        flex: 1;
        text-align: center;

        img {
          width: 100px;
          height: 100px;
          border-radius: 50%;
          object-fit: cover;
        }

        button {
          display: block;
          width: 150px;
          padding: 10px;
          border: none;
          background-color: teal;
          color: white;
          font-weight: bold;
          cursor: pointer;
          margin: 10px auto;

          &:disabled{
            background-color: rgba(3, 92, 92, 0.322);
            cursor: not-allowed;
          }
        }
      }

      .right {
        flex: 2;

        form {
          display: flex;
          flex-wrap: wrap;
          gap: 30px;
          justify-content: space-around;

          .formInput {
            width: 40%;

            label {
              display: flex;
              align-items: center;
              gap: 10px;

              .icon {
                cursor: pointer;
              }

              .warning {
                color: red;
                font-size: 12px;
              }
            }

            label.toggle {
              border-bottom: 1px solid gray;
              margin-top: 16px;
              padding: 5px;
              width: 100%;
              justify-content: space-between;
            }

            input {
              width: 100%;
              padding: 5px;
              border: none;
              border-bottom: 1px solid gray;
            }
          }
          button {
            margin: 10px 50px;
          }

          .chipInput {
            width: 90%;
            border-bottom: 1px solid gray;
            align-items: center;

            label {
              display: flex;
              align-items: center;
              gap: 10px;

              .icon {
                cursor: pointer;
              }

              .warning {
                color: red;
                font-size: 12px;
              }
            }

            .chipInputContainer {
              display: flex;
              gap: 5px;
              padding-bottom: 2px;

              .chip {
                margin: 1px;
              }
            
              input {
                width: 100%;
                padding: 5px;
                border: none;

                &:focus {
                  outline: none;
                }
              }

              &:focus-within {
                outline: 2px solid black;
                border-radius: 2px;
              }
            }

            
          }

        }
        button {
          display: block;
          width: 150px;
          padding: 10px;
          border: none;
          background-color: teal;
          color: white;
          font-weight: bold;
          cursor: pointer;
          margin: 10px auto;

          &:disabled{
            background-color: rgba(3, 92, 92, 0.322);
            cursor: not-allowed;
          }
        }
      }
    }

    .title {
      font-size: 16px;
      color: lightgray;
      margin-bottom: 20px;
    }
  }
}

.OPENButton {
    border: 1px dotted #F44336 !important;
    color: #F44336!important;
}
.IN.PROGRESSButton {
    border: 1px dotted #dbc70c !important;
    color: #c5b30d!important;
}

.COMPLETEDButton {
    border:1px dotted #4CAF50 !important;
    color: #4CAF50!important;
}

.DELAYEDButton {
    border:1px dotted #d76f07 !important;
    color: #c45f07!important;
}

.DEMOButton {
    border: 1px dotted #673AB7 !important;
    color: #673AB7!important;
}


.ACTIVEButton {
    border:1px dotted #4CAF50 !important;
    color: #4CAF50!important;
}

.LEADButton {
    border:1px dotted #155095 !important;
    color: #155095!important;
}

.DEMOButton {
    border: 1px dotted #673AB7 !important;
    color: #673AB7!important;
}

.DUEButton {
    border: 1px dotted #c5b30d !important;
    color: #c5b30d!important;
}

.CLOSEDButton {
    border: 1px dotted #F44336 !important;
    color: #F44336!important;
}
#settings {
  width: 50%;
  label {
    display: flex;
  }
  label.toggle {
    border-bottom: 1px solid gray;
    margin-top: 16px;
    padding: 5px;
    width: 100%;
    justify-content: space-between;
  }
}

.background-container {
  background-color: #f1f1ef;
  width: 98%;
  margin: auto;

  .form-generator-container {
    color: #233a6c;
    margin: 0px auto 0;
    text-align: left;
    max-width: 800px;
    // border: 1px solid lightgray;
    // border-radius: 20px;
    // box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    padding: 50px;

    .badge-danger {
      display: none !important;
    }

    .form-title {
      font-size: 38px;
      font-weight: 300;
    }

    .react-form-builder-form .rfb-item label span {
      background-color: #f1f1ef;
      padding: 0 !important;
      margin: 0 !important;
    }
  }

  // .custom-radio {
  //     background-color: black !important;
  // }
  // .custom-control-label {

  // }

  .custom-radio-passfail {
    width: 50%;
    margin-right: 0 !important;
    padding: 0 !important;
    text-align: center;
  }

  .custom-control-label-passfail {
    padding: 7px;
    width: 90%;
    background-color: #e7e7e7;
    border-radius: 5px;
  }

  /* Common styles for fail and broken */
  input[type="radio"][value="fail" i]:checked + label,
  input[type="radio"][value="fail" i] + label:hover,
  input[type="radio"][value="broken" i]:checked + label,
  input[type="radio"][value="broken" i] + label:hover,
  input[type="radio"][value="no" i]:checked + label,
  input[type="radio"][value="no" i] + label:hover {
    color: white;
    background-color: red;
    transition: 350ms;
  }

  input[type="radio"][value="pass" i]:checked + label,
  input[type="radio"][value="pass" i] + label:hover,
  input[type="radio"][value="sealed" i]:checked + label,
  input[type="radio"][value="sealed" i] + label:hover,
  input[type="radio"][value="yes" i]:checked + label,
  input[type="radio"][value="yes" i] + label:hover {
    color: white;
    background-color: #008000;
    transition: 350ms;
  }

  input[type="radio"][value="fail" i] + .custom-control-label-passfail::before,
  input[type="radio"][value="pass" i] + .custom-control-label-passfail::before,
  input[type="radio"][value="yes" i] + .custom-control-label-passfail::before,
  
  input[type="radio"][value="fail" i] + .custom-control-label-passfail::after,
  input[type="radio"][value="pass" i] + .custom-control-label-passfail::after,
  input[type="radio"][value="yes" i] + .custom-control-label-passfail::after,

  input[type="radio"][value="broken" i] + .custom-control-label-passfail::before,
  input[type="radio"][value="sealed" i] + .custom-control-label-passfail::before,
  input[type="radio"][value="no" i] + .custom-control-label-passfail::before,

  input[type="radio"][value="broken" i] + .custom-control-label-passfail::after,
  input[type="radio"][value="sealed" i] + .custom-control-label-passfail::after,
  input[type="radio"][value="no" i] + .custom-control-label-passfail::after {
    display: none !important;
  }

  // hide and display seal components based on sealed or broken radio button
  .SortableItem .new-seal-number, .SortableItem .replace {
    display: none;
  }

  .SortableItem:has(input[type="radio"][value="sealed"]:checked) + .SortableItem .replace {
    display: none;
  }

  .SortableItem:has(input[type="radio"][value="broken"]:checked) + .SortableItem .replace {
    display: block !important;
  }
  .SortableItem:has(input[type="radio"][value="no"]:checked) + .SortableItem .new-seal-number {
    display: none;
  }

  .SortableItem:has(input[type="radio"][value="yes"]:checked) + .SortableItem .new-seal-number {
    display: block !important;
  }

  .sealed-broken, .replace {
    margin-bottom: 0;
  }

  .btn {
    background-color: #233a6c;
    border: none;
    color: white;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    border-radius: 5px;
    margin: 0 5px;
    cursor: pointer;
    transition: 350ms;

    &:hover {
      background-color: #142442;
      transition: 350ms;
      color: white;
    }
  }
}

.print-view {
  display: none;
}

@media print {
  body * {
    visibility: hidden; /* Hide everything in the body when printing... */
  }

  .background-container,
  .background-container * {
    display: none !important;
  }
  // .form-generator-container, .form-generator-container * {
  //   visibility: visible; /* ...except for the form-generator-container and its children */
  // }
  // .form-generator-container {
  //   position: absolute;
  //   left: 0;
  //   top: 0;
  //   margin: 0;
  //   padding: 0;
  //   transform: scale(1.5);
  //   transform-origin: top left;
  //   width: 66.66%;

  //   span {
  //     background-color: white !important;
  //   }

  //   .btn {
  //     display: none;
  //   }
  // }
  .print-view * {
    visibility: visible;
  }
  .print-view {
    visibility: visible;
    padding: 0px 40px;
    position: absolute;
    left: 0;
    top: 0;
    transform: scale(1);
    transform-origin: top left;
    display: block;
    width: 100vw;

    .header {
      display: flex;
      justify-content: flex-start;
      height: 15vh;
      margin: 40px 0px;

      .logo {
        height: 100%;
        width: 25%;
        margin: 20px;

        img {
          height: 100%;
          width: 100%;
        }
      }

      .address {
        display: block;
        font-size: 26px;
        margin: 20px 0px;
      }
    }

    .form-title {
      font-size: 42px;
      font-weight: 800;
      margin: 20px 0px;
    }

    table {
      width: 100%;
      border-collapse: collapse;
      margin-bottom: 1em;
      font-size: 26px;

      th {
        width: 25%;
        padding: 0.5em;
        border: 1px solid darkgray !important;
        text-align: center;
        background-color: #f2f2f2;
        font-weight: bold;
      }

      td {
        width: 75%;
        padding: 0.5em;
        border: 1px solid darkgray !important;
        text-align: left;
      }
    }
  }
}

.onboarding-container {
    background-color: #2c2c2c; 
    color: #ffffff;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .logo-container {
    margin-bottom: 20px;
  }
  
  .logo {
    max-width: 100%;
    height: auto;
    max-width: 300px;
  }

  .info-text {
    margin-top: 10px;
    font-size: 16px;
    color: #ffffff;
    margin-bottom: 20px;;
  }
  
  .onboarding-form {
    background-color: #3d3d3d;
    padding: 50px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    width: 500px;
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  .form-group label {
    display: block;
    margin-bottom: 5px;
  }
  
  .form-group input {
    width: 100%;
    padding: 8px;
    border: none;
    border-radius: 4px;
    box-sizing: border-box;
  }
  
  .submit-button {
    background-color: #5cb85c;
    color: #ffffff;
    padding: 10px 15px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .submit-button:hover {
    background-color: #4cae4c;
  }
  
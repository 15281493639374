#comments-text-area {
  font-weight: 600;
}

.formComments {
  width: 70% !important;
}

.formDate {
  width: 14% !important;
}

.formInput {
  margin-bottom: 20px; /* Add margin between form inputs */
}

.formInput label {
  display: block; /* Display labels as block elements */
  margin-bottom: 5px; /* Add margin between labels and inputs */
}

.formInput input,
.formInput textarea {
  width: 100%; /* Make the inputs and textareas take the full width of the container */
  padding: 8px; /* Add padding for better visual appearance */
  box-sizing: border-box; /* Include padding and border in the element's total width and height */
}

.feedsForm {
  display: flex !important;
  flex-direction: column;

  .feeds {
    display: flex;
    flex-direction: row;

    .feedOptions {
      margin-left: 15px;
      display: flex;
      flex-direction: column;
      width: 100%;
    }
  }
}

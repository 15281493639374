.switchAgency {
  width: 100%;
  display: flex;

  .switchAgencyContainer {
    flex: 6;

    .container {
      height: 600px;
      padding: 20px;

      .title {
        width: 100%;
        font-size: 24px;
        color: gray;
        margin-bottom: 10px;
        display: flex;
        align-items: center;
      }

      .agencyWidgetsContainer {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
        margin-bottom: 20px;

        .agencyWidget {
          width: 250px;
          height: 120px;
          border-radius: 20px;
          display: flex;
          align-items: center;
          justify-content: space-evenly;
          -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
          box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
          font-size: 18px;
          cursor: pointer;
          margin: 10px;
          transition: background-color 0.3s ease;
          border: 2px solid transparent;
        }

        .agencyWidget:hover {
          background-color: #f7f7f7;
        }

        .agencyWidget.selected {
          border: 2px solid #555;
        }
      }
    }
  }
}

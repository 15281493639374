.deleteButton {
  padding: 5px 10px;
  background-color: red;
  color: white;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  &:hover {
    background-color: darkred;
  }
}

.editButton {
  padding: 5px 10px;
  border-radius: 5px;
  color: white;
  cursor: pointer;
  border: none;
  background-color: blue;
  &:hover {
    background-color: darkblue;
  }
}

.viewFileButton {
  padding: 5px 10px;
  border-radius: 5px;
  color: white;
  cursor: pointer;
  border: none;
  background-color: green;
  &:hover {
    background-color: darkgreen;
  }
}
.login-page {
  display: flex;
  min-height: 100vh;

  .img-container {
    background: url('./firemen.jpg') no-repeat center center;
    background-size: cover;
    // make this container width 100% of the remaining space
    flex: 1;
    font-size: 4rem;
    // center the text vertically
    display: flex;
    justify-content: center;
    align-items: center;


    .img-text {
      background-color: #eb2529;
      font-size: 3.2rem;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      color: white;
      font-weight: 600;
      font-family: Arial, Helvetica, sans-serif;
      padding-left: 5px;
      padding-right: 5px;
    }
  }

  .login-container {
    width: 500px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    flex-direction: column;
    // add box shadow to left side of the container
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.4);

    .login-logo {
      margin-bottom: 26px;
      width: 100%;
      max-width: 300px;
    }

    .login-form {
      width: 100%;
      max-width: 425px;
      padding: 20px;
      display: flex;
      flex-direction: column;

      .login-title {
        text-align: center;
        margin-bottom: 20px;
      }

      .login-input {
        margin-bottom: 10px;
        border: 1px solid #ccc;
        padding: 16px;
        border-radius: 4px;
        font-size: 1.1rem;

        &:focus {
          border-color: #007bff;
          box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.25); 
          // Remove the outline when focused
          outline: none;
        }
      }

      .login-remember {
        display: flex;
        align-items: center;
        margin-bottom: 30px;
        
        input[type="checkbox"] {
          margin-right: 5px;
        }
      }

      .login-button {
        width: 100%;
        padding: 16px;
        border-radius: 10px;
        background-color: #007bff;
        font-weight: 900;
        color: white;
        border: none;
        cursor: pointer;

        &:hover {
          background-color: #0056b3;
        }
      }
    }
  }

  .warning {
    color: #eb2529;
    font-size: 0.85rem;
    font-weight: 800;
    margin-top: 20px;
    max-width: 425px;
    span {
      width: 100%;
      text-align: center;
      display: block;
      margin-bottom: 6px;
    }
  }
}
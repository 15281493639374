.title {
  width: 100%;
  font-size: 24px;
  color: gray;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.padding {
  padding: 20px;
}

.formInput {
  width: 40%;
  margin-bottom: 10px;

  label {
    display: flex;
    align-items: center;
    gap: 10px;

    .icon {
      cursor: pointer;
    }

    .warning {
      color: red;
      font-size: 12px;
    }
  }

  label.toggle {
    padding: 5px;
    width: 100%;
  }
}

.inputContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  button {
    padding: 8px 16px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: #0056b3; // Darker color on hover
    }
  }

  input {
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 33%;
    box-sizing: border-box;
    margin-right: 25px;

    &:focus {
      outline: none;
      border-color: #007bff; // Change the border color on focus
    }
  }
}

.pulsing-marker {
  animation: pulse 2s infinite;
}

@keyframes pulse {
  0% {
    transform: translate(-50%, -50%) scale(0.5);
    opacity: 0.8;
  }
  70% {
    transform: translate(-50%, -50%) scale(2);
    opacity: 0;
  }
  100% {
    transform: translate(-50%, -50%) scale(0.5);
    opacity: 0;
  }
}

.pulse-ring {
  position: absolute;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: rgba(0, 255, 0, 0.4);
  transform: translate(-50%, -50%);
  animation: pulse 2s infinite;
}

.center-marker {
  position: absolute;
  width: 12px;
  height: 12px;
  background-color: #00ff00;
  border: 2px solid white;
  border-radius: 50%;
  transform: translate(-50%, -50%);
}

.crosshair-horizontal {
  position: absolute;
  width: 20px;
  height: 2px;
  background-color: rgba(0, 255, 0, 0.8);
  transform: translate(-50%, -50%);
}

.crosshair-vertical {
  position: absolute;
  width: 2px;
  height: 20px;
  background-color: rgba(0, 255, 0, 0.8);
  transform: translate(-50%, -50%);
}

.caller-label {
  position: absolute;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 12px;
  white-space: nowrap;
  font-weight: bold;
}

// Override Google Maps marker styles to allow animation
.gm-style img[src*="data:image"] {
  opacity: 1 !important;
  transition: all 0.3s ease-in-out;
}

.new {
  display: flex;
}

.newContainer {
  flex: 1;
  padding: 20px;
}

.top {
  text-align: center;
  margin-bottom: 20px;
}

.bottom {
  display: flex;
}

.left {
  flex: 1;
}

.right {
  flex: 2;
  padding-left: 20px;
}

.boxed-container {
  border: 1px solid #ccc;
  padding: 20px;
  position: relative;
}

.boxed-container img {
  width: 100% !important;
  height: 100% !important;
  border-radius: 0% !important;
}

.boxed-container::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border: 2px solid #000;
  pointer-events: none;
  box-sizing: border-box;
}
select {
  width: 100%;
  height: 30px;
}
